<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">فواتير الطلاب المنقولين</h1>
      <v-row>
        <v-col md="4" cols="12">
          <v-btn tile color="success" :loading="xlsxData.downloadLoading" :block="isScreenXs" @click="getAllDataAxios">
            تحميل اكسل <v-icon right> fa-download </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="class_data.class_id"
            :items="class_data.list"
            :loading="class_data.loading"
            :item-text="item => item.class_name + ' - ' + item.leader"
            item-value="_id"
            clearable
            outlined
            dense
            label="الصف والشعبة"
            @change="getDataAxios"
          ></v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white">fa-search</v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template v-slot:item.account_division_current="{ item }">
              {{
                (item.account_division_current ? item.account_division_current.class_name : '') +
                ' - ' +
                (item.account_division_current ? item.account_division_current.leader : '')
              }}
            </template>
            <template v-slot:item.forthisYearSalaryAmount="{ item }">
              {{ numberWithComma(item.forthisYearSalaryAmount) }}
            </template>
            <template v-slot:item.forthisYearPaymentAmount="{ item }">
              {{ numberWithComma(item.forthisYearPaymentAmount) }}
            </template>
            <template v-slot:item.forthisYearDiscountAmount="{ item }">
              {{ numberWithComma(item.forthisYearDiscountAmount) }}
            </template>
            <template v-slot:item.forthisYearRemaining="{ item }">
              {{ numberWithComma(item.forthisYearRemaining) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom v-if="!tableModifier.isDeleted">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" size="20" v-on="on" @click="transferredStudent(item)">
                    fa-undo
                  </v-icon>
                </template>
                <span>استرجاع</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- recover dialog -->
    <v-dialog v-model="dialogRecover.open" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من استرجاع فواتير هذا الحساب ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogRecover.open = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="dialogRecover.loading" @click="transferredStudentSubmit">
            استرجاع
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End recover dailog -->
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'
import numberWithComma from '@/constant/number'
import { mdiCash, mdiCashMultiple } from '@mdi/js'

export default {
  data () {
    return {
      dialogRecover: {
        loading: false,
        open: false,
        item: {},
      },

      icons: {
        mdiCashMultiple,
        mdiCash,
        mdiCashMultiple,
      },

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: 'قواتير الطلاب',
        autoWidth: true,
        bookType: 'xlsx',
      },

      tableModifier: {
        billType: null,
        isDeleted: false,
        start_date: null,
        end_date: null,
      },

      rules: {
        student_id: [value => !!value || 'اسم الطالب مطلوب'],
        service_type: [value => !!value || 'الخدمة مطلوبة'],
        salaryAmount: [value => !!value || 'المبلغ مطلوب'],
        type: [value => !!value || 'نوع الفاتورة مطلوب'],
        date: [value => !!value || 'تاريخ انشاء الفاتورة مطلوب'],
        required: [value => !!value || 'الحقل مطلوب'],

        // next_payment: [value => !!value || 'تاريخ الدفعة القادمة مطلوب'],
      },

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      allData: [],

      isScreenXs: false,

      tableOptions: {},

      allData: [],

      class_data: {
        loading: false,
        list: [],
        class_id: null,
      },

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
            width: 1,
          },
          {
            text: 'اسم الطالب',
            sortable: false,
            value: 'student_name',
          },
          { text: 'الصف والشعبة', value: 'account_division_current' },
          { text: 'الهاتف', value: 'student_mobile' },
          { text: 'المبلغ الكلي', sortable: false, value: 'forthisYearSalaryAmount' },
          { text: 'المبلغ المدفوع', sortable: false, value: 'forthisYearPaymentAmount' },
          { text: 'الخصم', sortable: false, value: 'forthisYearDiscountAmount' },
          { text: 'المتبقي', sortable: false, value: 'forthisYearRemaining' },
          { text: 'ملاحظة النقل', sortable: false, value: 'transferred_note' },
          // { text: 'مجموع المبلغ الكلي لكل السنين', sortable: false, value: 'salaryAllAmount' },
          // { text: 'مجموع المبلغ المدفوع لكل السنين', sortable: false, value: 'paymentAllAmount' },
          // { text: 'مجموع الخصم لكل السنين', sortable: false, value: 'discountAllAmount' },
          // { text: 'المتبقي لكل السنين', sortable: false, value: 'remainingAll' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },
  watch: {
    tableOptions: {
      handler () {
        this.getDataAxios()
      },
      deep: true,
    },

    '$route.query.search': {
      handler (search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
  },

  created () {
    this.account_type = JSON.parse(localStorage.getItem('results')).account_type
    this.getClassSchool()
  },

  methods: {
    async getDataAxios () {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = ''
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getStudentTransferredBills({
        is_transferred: true,
        isDeleted: this.tableModifier.isDeleted,
        isPaid: this.tableModifier.billType,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        study_year,
        page,
        limit: itemsPerPage,
        search,
        class_id: this.class_data.class_id,
      })
      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
        this.content_url = response.data.content_url
        this.studentData = response.data.results.studentData
      }
    },

    async getClassSchool () {
      this.class_data.loading = true

      const response = await Api.getClassSchool()
      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.class_data.loading = false
        this.showDialogfunction(response.data.results, '#FF5252')
      } else {
        this.class_data.loading = false
        this.class_data.list = response.data.results
      }
    },

    search () {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    deleteItem (item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.deleteItemLoading = true

      const response = await Api.removeStudentBills(this.deletedItem.student_id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getDataAxios()
        this.showDialogfunction(response.data.results, 'primary')
      }
    },

    isNumber (evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    showDialogfunction (bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    transferredStudent (item) {
      this.dialogRecover.open = true
      this.dialogRecover.item.student_id = item.student_id
    },

    async transferredStudentSubmit () {
      this.dialogRecover.loading = true

      const response = await Api.transferredStudentDB({
        is_transferred: false,
        transferred_note: null,
        student_id: this.dialogRecover.item.student_id,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogRecover.loading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.dialogRecover.loading = false
        this.dialogRecover.open = false
        this.showDialogfunction(response.data.message, 'primary')
        this.getDataAxios()
      }
    },

    async getAllDataAxios () {
      this.xlsxData.downloadLoading = true

      let { search } = this.table
      if (!search) {
        search = ''
      }

      const study_year = JSON.parse(localStorage.getItem('study_year'))

      const response = await Api.getAllStudentBills({
        isDeleted: this.tableModifier.isDeleted,
        isPaid: this.tableModifier.billType,
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        study_year,
        class_school_id: 'all',
        search,
        is_transferred: true,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.xlsxData.downloadLoading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.xlsxData.downloadLoading = false
        this.allData = response.data.results
        this.handleDownload()
      }
    },

    handleDownload () {
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['اسم الطالب', 'المبلغ الكلي', 'المبلغ المدفوع', 'الخصم', 'المتبقي']
        const filterVal = [
          'student_name',
          'forthisYearSalaryAmount',
          'forthisYearPaymentAmount',
          'forthisYearDiscountAmount',
          'forthisYearRemaining',
        ]

        // const { list } = this
        const data = this.formatJson(filterVal, this.allData)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    numberWithComma,
  },
}
</script>

<style scoped>
.image_table {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.search-btn {
  width: 49px;
  height: 40px;
  padding: 10px;
  background: #2196f3;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  cursor: pointer;
  line-height: 100%;
  border-radius: 7px;
}

table.v-table tbody td {
  color: black;
}
</style>
